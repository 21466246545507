// @flow

import React from 'react';
import {observer} from 'mobx-react';
import * as classNames from 'classnames';
import isString from 'lodash/isString';
import includes from 'lodash/includes';
import {action, observable} from "mobx";

import parseMarkdown from '../../utils/parse-markdown';
import styles from './style.module.scss';
import dashToCamelCase from "../../utils/dash-to-camel-case";

type MarkdownProps = {
  markdown: any,
  centerContent?: boolean,
  textOnly?: boolean,
  listImageUrl?: string,
  textColor?: string,
}

@observer
class Markdown extends React.Component<MarkdownProps, any> {

  @observable active: boolean = false;

  @action
  setActive = (active: boolean): void => {
    this.active = active;
  };

  onClick = (e: any) => {
    const active: boolean = !this.active;
    e.preventDefault();
    e.__scheduleButtonClicked = true;
    e.__scheduleButtonState = active;
    this.setActive(active);
  };

  render() {

    if (!this.props.markdown) {
      return (<>

      </>)
    }

    const markdown = parseMarkdown(isString(this.props.markdown) ?
      this.props.markdown :
      this.props.markdown.markdown);

    return (
      <div className={classNames({
        [styles[this.props.textColor]]: true,
        [styles.container]: true,
        [styles.textOnly]: this.props.textOnly,
        [styles.centerContent]: this.props.centerContent
      })}>
        {markdown.map((item: any, index) => {
          return (
            <React.Fragment key={`${index}markdown`}>
              {item.type === 'p' && <p dangerouslySetInnerHTML={{
                __html: item.display
              }}>

              </p>}
              {item.type === 'h1' && <h1 dangerouslySetInnerHTML={{
                __html: item.display
              }}>

              </h1>}
              {item.type === 'h2' && <h2 dangerouslySetInnerHTML={{
                __html: item.display
              }}>

              </h2>}
              {item.type === 'h3' && <h3 dangerouslySetInnerHTML={{
                __html: item.display
              }}>

              </h3>}
              {item.type === 'h4' && <h4 dangerouslySetInnerHTML={{
                __html: item.display
              }}>

              </h4>}
              {item.type === 'div' && <div dangerouslySetInnerHTML={{
                __html: item.display
              }} className={styles.code}>

              </div>}
              {item.type === 'divider' && <div className={styles.divider}>

              </div>}
              {item.type === 'button' && <div className={classNames({
                [styles.button]: true,
                [styles[dashToCamelCase(item.buttonType)]]: true
              })}>

              </div>}
              {item.type === 'ul' && <div className={classNames({
                [styles.ul]: true,
              })}>
                {item.content.map((ulItem, ulIndex) => {
                  const indent = includes(ulItem, ':indent:');
                  if (indent) {
                    ulItem = ulItem.replace(':indent:', '');
                  }
                  return (
                    <div key={`${ulIndex}markdownUl`}
                         className={classNames({
                           [styles.li]: true,
                           [styles.indent]: indent
                         })}>
                      <div className={styles.dotWrapper}>
                        {!this.props.listImageUrl && <div className={styles.dot}>

                        </div>}
                        {this.props.listImageUrl && <div className={styles.circle}>
                          <img src={this.props.listImageUrl} />
                        </div>}
                      </div>
                      <div className={styles.text}
                           dangerouslySetInnerHTML={{
                             __html: ulItem
                           }}>

                      </div>
                    </div>
                  )
                })}
              </div>}
            </React.Fragment>
          )
        })}
      </div>
    )
  }
}

export default Markdown;
