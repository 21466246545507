// @flow

import React from "react";
import { graphql } from "gatsby";
import { observable, action } from "mobx";
import { observer, inject } from "mobx-react";
import includes from "lodash/includes";

import * as classNames from "classnames";
import { Provider } from "mobx-react";
import Metadata from "../components/Metadata";
import UiStore from "../stores/ui-store";
import CartStore from "../stores/cart-store";
import JsonStore from "../stores/json-store";
import { STORE_UI, STORE_JSON, STORE_CART } from "../constants/stores";
import { JSON_GATE } from "../constants/json";
import styles from "./page.module.scss";
import FeedItem from "../components/FeedItem";
import InventoryItem from "../components/InventoryItem";
import ReactResizeDetector from "react-resize-detector";

type PageProps = {
  data: any,
  location: any,
  ui: UiStore,
  cart: CartStore,
  json: JsonStore,
};

@inject(STORE_UI, STORE_JSON, STORE_CART)
@observer
class PagePage extends React.Component<PageProps, any> {
  stores: any;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props[STORE_JSON].setJsonFromGraphQL(
      this.props.data,
      this.props.data.page.url
    );
    this.props[STORE_JSON].setJsonFromGraphQL(this.props.data, JSON_GATE);
    this.props[STORE_UI].bgToggle(this.props.data.page.url);
  }

  @observable
  subscribe: string = `REGISTER YOUR EMAIL TO SIGN UP FOR PURE BEAUTY SPAM`;

  @action
  setSubscribe = (subscribe: string): void => {
    this.subscribe = subscribe;
  };

  @observable email: string = ``;

  @action
  setEmail = (email: string): void => {
    this.email = email;
  };

  @observable width: number = 0;

  @action
  setWidth = (width: number): void => {
    this.width = width;
  };

  handleResize = (width: number) => {
    if (width !== 0) {
      this.setWidth(width);
    }
  };

  componentDidMount() {
    if (window) {
      window.addEventListener("keydown", this.keyListenBound);
      setTimeout(() => this.props[STORE_UI].checkAgeGate(), 300);
    }
    if (process.env.GATSBY_PREVIEW === "true" && window) {
      this.props.json.getData(JSON_GATE, {
        contentType: "ui",
        id: "6eX13vamRU8O8boGpwxC24",
      });
      this.props.json.getData(
        "page",
        {
          contentType: "page",
          id: this.props.data.page.contentful_id,
        },
        false,
        this.props.data.page.url
      );
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener("keydown", this.keyListenBound);
  }

  submit() {
    if (this.email) {
      var params = {
        "data": {
          "type": "subscription",
          "attributes": {
            "list_id": "XNyisi",
            "email": this.email,
            "custom_source": "purebeautypurebeauty.co/subscribe/",
          }
        }
      };

      fetch(`https://a.klaviyo.com/client/subscriptions/?company_id=Tu9JkM`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          'revision': `2023-01-24`,
        },
        body: JSON.stringify(params),
      })
        .then(response => {
          if (response && response.ok) {
            this.setSubscribe(`THANK YOU`);
            this.setEmail(``);
          } else {
            this.setSubscribe(`SOMETHING IS WRONG PLEASE TRY AGAIN`);
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  input(val: string) {
    this.setEmail(val);
  }

  keyListenBound: any = this.keyListen.bind(this);

  keyListen(e: any) {
    if (this.props.data.page.url === "subscribe") {
      if (e.keyCode === 13) {
        this.submit();
      }
    }
  }

  render() {
    const data: any =
      this.props[STORE_JSON].store.get(this.props.data.page.url) ||
      this.props.data;
    return (
      <Provider {...this.stores}>
        <>
          <Metadata
            data={this.props.data.page.metadata}
            location={this.props.location}
          />
          <div
            className={classNames({
              [styles.wrapper]: true,
              [styles.animate]: this.props.data.page.animate,
            })}
            style={{
              minHeight: `${this.props.ui.windowHeight}px`,
            }}
          >
            <div
              className={styles.inner}
              style={{
                flexWrap: this.props.data.page.wrap ? "wrap" : null,
                flexDirection: this.props.data.page.wrap ? "row" : "column",
                alignItems: this.props.data.page.wrap ? "flex-start" : null,
                padding:
                  this.props.ui.isMobile ||
                  (this.props.data.page.animate &&
                    this.props.ui.isMobileAnimated)
                    ? `0 calc(${data.page.paddingMobile}rem / 16)`
                    : `0 calc(${data.page.paddingDesktop}rem / 16)`,
                maxWidth: `${data.page.maxWidth}px`,
              }}
            >
              <ReactResizeDetector handleWidth onResize={this.handleResize} />
              {data.page.feed &&
                data.page.feed.map((feedItem: any, index) => {
                  return (
                    <React.Fragment key={`HomepageFeed-${index}`}>
                      {includes(
                        ["ContentfulFeedItem", "feedItem"],
                        feedItem.contentType
                      ) && (
                        <FeedItem
                          parentWidth={
                            this.props.data.page.animate ? this.width : null
                          }
                          animate={this.props.data.page.animate}
                          data={feedItem}
                          ui={this.props.ui}
                        />
                      )}
                      {includes(
                        ["ContentfulInventoryItem", "inventoryItem"],
                        feedItem.contentType
                      ) && (
                        <InventoryItem
                          data={feedItem}
                          cart={this.props.cart}
                          ui={this.props.ui}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              {this.props.data.page.url === "subscribe" && (
                <div className={styles.register}>
                  <p>{this.subscribe}</p>
                  <input
                    onChange={e => this.input(e.target.value)}
                    type="text"
                    value={this.email}
                    name={`email`}
                    autoComplete={`email`}
                  />
                  <div className={styles.button} onClick={() => this.submit()}>
                    {`SUBMIT`}
                  </div>
                </div>
              )}
              {this.props.data.page.url === "jobs" && (
                <div className={styles.jobs}>
                  <span className={styles.job}>
                    {/* SORRY FULL HOUSE AT THE MOMENT. PLEASE CHECK BACK LATER */}
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      </Provider>
    );
  }
}

export const query = graphql`
  query($url: String!) {
    gate: contentfulUi(contentful_id: { eq: "6eX13vamRU8O8boGpwxC24" }) {
      ageGateBackgrounds {
        color
        overlayColor
        image {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        video {
          file {
            url
          }
        }
      }
    }
    page: contentfulPage(url: { eq: $url }) {
      contentful_id
      animate
      wrap
      url
      maxWidth
      paddingDesktop
      paddingMobile
      background {
        overlayColor
        image {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
      feed {
        contentType: __typename
        ... on ContentfulInventoryItem {
          name
          price
          inventory {
            size
            stock
          }
          images {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
          }
        }
        ... on ContentfulFeedItem {
          blurb {
            markdown: blurb
          }
          blurbColor
          breakLine
          marginBottom
          shiftHorizontal
          shiftVertical
          videoAspectRatio
          video {
            file {
              url
            }
          }
          imageOrientation
          imageWidth
          imageWidthMobile
          image {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
          }
        }
      }
      metadata {
        title
        description {
          text: description
        }
        shareImage {
          file {
            url
          }
        }
      }
    }
  }
`;

export default PagePage;
