// @flow

import React from 'react';
import { observer } from 'mobx-react';
import {observable, action} from 'mobx';
import ReactResizeDetector from 'react-resize-detector';

import styles from './style.module.scss';
import uiStore from '../../stores/ui-store';
import cartStore from '../../stores/cart-store';
import { Money, CartItem } from '../../stores/cart-store';
import type { Image } from '../BlurUpImage';
import BlurUpImage from '../BlurUpImage';
import * as classNames from 'classnames';

export type InventoryItemData = {
  name: string,
  price: number,
  inventory: {
    size: string,
    stock: number
  }[],
  images: Image[]
}

type InventoryItemProps = {
  ui: uiStore,
  cart: cartStore,
  data: InventoryItemData,
}

@observer
class InventoryItem extends React.Component<InventoryItemProps, any> {

  value: any;
  ref: any;
  width: number = 0;

  @observable index: number = 0;

  @action
  setIndex = (index: number): void => {
    this.index = index;
  };


  constructor(props: InventoryItemProps) {
    super(props);
    this.ref = React.createRef();
  }

  input(value: string) {
    this.value = value;
  }
  
  addCart() {
    if (this.value) {
      this.props.cart.add(new CartItem(this.props.data.images[0], this.props.data.name, new Money(this.props.data.price), this.value));
    }
  }

  handleResize = (width: number) => {
    if (width !== 0) this.width = width;
  };

  scrollForward() {
    if (this.ref.current) {
      this.ref.current.scrollTo({
        left: this.width * (this.index + 1),
        behavior: 'smooth'
      });
    }
  }

  scrollBack() {
    if (this.ref.current) {
      this.ref.current.scrollTo({
        left: this.width * (this.index - 1),
        behavior: 'smooth'
      });
    }
  }

  scrolled(target: any) {
    this.setIndex(Math.round(target.scrollLeft / this.width));
  }

  render() {

    return (
      <div className={classNames({
        [styles.wrapper]: true,
      })}>
        <div className={styles.images}
             onScroll={(ev) => this.scrolled(ev.target)}
             ref={this.ref}>
          <ReactResizeDetector handleWidth onResize={this.handleResize} />
          <div className={styles.imagesWrapper}
               style={{
                 width: `${this.props.data.images.length * 100}%`
               }}>
            {this.props.data.images.map((image, index) => {
              return (
                <div className={styles.imageWidthWrapper}
                     style={{
                       width: `${100 / this.props.data.images.length}%`,
                     }}
                     key={`InventoryImage-${index}`}>
                  <div className={styles.imageWrapper}
                       style={{
                         paddingTop: `${
                         image.file.details.image.height /
                         image.file.details.image.width * 100}%`
                       }}>
                    <BlurUpImage image={image}/>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={styles.buttonsWrapper}
             style={{
               paddingTop: `${
               this.props.data.images[0].file.details.image.height /
               this.props.data.images[0].file.details.image.width * 100}%`
             }}>
          <div className={styles.buttons}>
            <div className={classNames({
              [styles.back]: true,
              [styles.active]: this.index !== 0
            })}
                 onClick={() => this.scrollBack()}>
              {`<`}
            </div>
            <div className={classNames({
              [styles.forward]: true,
              [styles.active]: this.index < this.props.data.images.length - 1
            })}
                 onClick={() => this.scrollForward()}>
              {`>`}
            </div>
          </div>
        </div>
        <p>
          {this.props.data.name}
        </p>
        <p>
          {`$${this.props.data.price}.00`}
        </p>
        <div className={styles.buttons}>
          <select defaultValue={`select`}
                  onInput={(e: any) => this.input(e.target.value)}>
            <option disabled value={`select`}>
              SELECT SIZE
            </option>
            {this.props.data.inventory.map((item: any, index: number) => {
              return item.stock > 0 ? (
                <option value={item.size}
                        key={`${this.props.data.name}-${index}`}>
                  {item.size}
                </option>
              ) : (<></>)
            })}
          </select>
          <div className={styles.cart}
               onClick={() => this.addCart()}>
            ADD TO CART
          </div>
        </div>
      </div>
    )
  }
}

export default InventoryItem;
