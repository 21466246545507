// @flow

import React from "react";
import { observer } from "mobx-react";
import isNumber from "lodash/isNumber";
import { Bezier } from "bezier-js";

import styles from "./style.module.scss";
import uiStore from "../../stores/ui-store";
import type { Image } from "../BlurUpImage";
import BlurUpImage from "../BlurUpImage";
import Markdown from "../Markdown";
import * as classNames from "classnames";
import ReactResizeDetector from "react-resize-detector";
import { action, observable } from "mobx";

export type FeedItemData = {
  contentType?: string,
  imageOrientation: string,
  imageWidth: number,
  imageWidthMobile: number,
  imageUrl?: string,
  image: Image,
  video?: any,
  videoAspectRatio?: number,
  marginBottom?: number,
  shiftHorizontal?: number,
  shiftVertical?: number,
  breakLine?: boolean,
  blurb?: {
    markdown: any,
  },
  blurbColor?: string,
};

type FeedItemProps = {
  ui: uiStore,
  data: FeedItemData,
  parentWidth?: number,
  hidden?: boolean,
  animate?: boolean,
};

const ANIMATION_TIME_SEC_MIN = 40;
const ANIMATION_TIME_SEC_MAX = 60;
const X_MAX = 150;
const X_MIN = -150;
const Y_MAX = 150;
const Y_MIN = -150;
const Z_MAX = 100;
const Z_MIN = 0;

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

@observer
class FeedItem extends React.Component<FeedItemProps, any> {
  points = [];
  count = 0;
  curveCount = 0;
  width = 0;
  height = 0;
  cancel = null;

  @observable loaded: boolean = false;

  @action
  setLoaded = (loaded: boolean): void => {
    this.loaded = loaded;
  };

  constructor(props: FeedItemProps) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.props.animate && this.props.data.image) this.setRefLoop();
    setTimeout(() => this.setLoaded(true), 300);
  }

  componentWillUnmount() {
    if (this.cancel) window.cancelAnimationFrame(this.cancel);
  }

  handleResize = (width: number, height: number) => {
    if (width !== 0) this.width = width;
    if (height !== 0) this.height = height;
  };

  animate() {
    if (!this.points[this.count]) {
      this.count = 0;

      const initial =
        this.points.length > 0
          ? this.points[this.points.length - 1]
          : { x: 0, y: 0, z: 0 };
      const frames =
        randomIntFromInterval(ANIMATION_TIME_SEC_MIN, ANIMATION_TIME_SEC_MAX) *
        60;
      this.points = new Bezier(
        initial,
        {
          x: randomIntFromInterval(X_MIN, X_MAX),
          y: randomIntFromInterval(Y_MIN, Y_MAX),
          z: randomIntFromInterval(Z_MIN, Z_MAX),
        },
        {
          x: randomIntFromInterval(X_MIN, X_MAX),
          y: randomIntFromInterval(Y_MIN, Y_MAX),
          z: randomIntFromInterval(Z_MIN, Z_MAX),
        }
      ).getLUT(frames);
      this.curveCount++;
    }
    if (this.ref.current)
      this.ref.current.style.transform = `perspective(500px) translate3d(${
        this.points[this.count].x
      }px, ${this.points[this.count].y}px, ${this.points[this.count].z}px)`;
    this.count++;
    this.cancel = window.requestAnimationFrame(() => this.animate());
  }

  setRefLoop() {
    if (this.ref.current && this.width && this.height) {
      this.cancel = window.requestAnimationFrame(() => this.animate());
    } else setTimeout(() => this.setRefLoop(), 60);
  }

  render() {
    return (
      <div
        className={classNames({
          [styles.animate]: this.props.data.image && this.props.animate,
          [styles.animateContainer]: this.props.animate,
          [styles.hidden]: this.props.hidden,
          [styles.noMargin]: !!this.props.parentWidth,
          [styles.wrapper]: true,
          [styles[this.props.data.imageOrientation]]: true,
        })}
        style={{
          opacity: this.props.animate && !this.loaded ? 0 : 1,
          marginBottom: isNumber(this.props.data.marginBottom)
            ? `${this.props.parentWidth *
                (this.props.data.marginBottom / 100)}px`
            : null,
          transform:
            isNumber(this.props.data.shiftHorizontal) ||
            isNumber(this.props.data.shiftVertical)
              ? `${
                  isNumber(this.props.data.shiftHorizontal)
                    ? "translateX(" +
                      this.props.parentWidth *
                        (this.props.data.shiftHorizontal / 100) +
                      "px) "
                    : ""
                }
            ${
              isNumber(this.props.data.shiftVertical)
                ? "translateY(" +
                  this.props.parentWidth *
                    (this.props.data.shiftVertical / 100) +
                  "px)"
                : ""
            }`
              : "none",
          marginLeft:
            !(
              this.props.ui.isMobile ||
              (this.props.animate && this.props.ui.isMobileAnimated)
            ) && this.props.data.breakLine
              ? `${(100 - this.props.data.imageWidth) / 2}%`
              : null,
          marginRight:
            !(
              this.props.ui.isMobile ||
              (this.props.animate && this.props.ui.isMobileAnimated)
            ) && this.props.data.breakLine
              ? `${(100 - this.props.data.imageWidth) / 2}%`
              : null,
          maxWidth:
            this.props.animate &&
            this.props.data.video &&
            !this.props.ui.isMobileAnimated
              ? "800px"
              : null,
          width: `${
            this.props.ui.isMobile ||
            (this.props.animate && this.props.ui.isMobileAnimated)
              ? this.props.data.imageWidthMobile || 100
              : this.props.data.imageWidth || 100
          }%`,
        }}
      >
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.handleResize}
        />
        {this.props.data.image && (
          <>
            {this.props.data.imageUrl ? (
              <a
                href={this.props.data.imageUrl}
                ref={this.ref}
                className={styles.imageWrapper}
                style={{
                  paddingTop: `${(this.props.data.image.file.details.image
                    .height /
                    this.props.data.image.file.details.image.width) *
                    100}%`,
                }}
              >
                <BlurUpImage image={this.props.data.image} />
              </a>
            ) : (
              <div
                ref={this.ref}
                className={styles.imageWrapper}
                style={{
                  paddingTop: `${(this.props.data.image.file.details.image
                    .height /
                    this.props.data.image.file.details.image.width) *
                    100}%`,
                }}
              >
                <BlurUpImage image={this.props.data.image} />
              </div>
            )}
          </>
        )}
        {this.props.data.video && (
          <div
            className={styles.videoWrapper}
            style={{
              paddingTop: `${this.props.data.videoAspectRatio * 100}%`,
            }}
          >
            <div>
              <video
                controls
                autoPlay
                playsInline
                loop
                muted
                src={this.props.data.video.file.url}
              />
            </div>
          </div>
        )}
        {this.props.data.blurb && (
          <div className={styles.blurb}>
            <Markdown
              textColor={this.props.data.blurbColor}
              markdown={this.props.data.blurb}
            />
          </div>
        )}
      </div>
    );
  }
}

export default FeedItem;
